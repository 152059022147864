// Generated by Framer (28b2919)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["Q7i21dvUF", "y0IJ4UOM7"];

const variantClassNames = {Q7i21dvUF: "framer-v-1x59op2", y0IJ4UOM7: "framer-v-8l0vw8"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"Logo Desktop": "Q7i21dvUF", "Logo Mobile": "y0IJ4UOM7"}

const getProps = ({height, id, link, width, ...props}) => { return {...props, PREstkul_: link ?? props.PREstkul_, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "Q7i21dvUF"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;link?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, PREstkul_, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "Q7i21dvUF", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-l7SQW", classNames)} style={{display: "contents"}}>
<Transition value={transition}><Link href={PREstkul_}><motion.a {...restProps} className={`${cx("framer-1x59op2", className)} framer-1ep1dfx`} data-framer-name={"Logo Desktop"} layoutDependency={layoutDependency} layoutId={"Q7i21dvUF"} ref={ref} style={{...style}} {...addPropertyOverrides({y0IJ4UOM7: {"data-framer-name": "Logo Mobile"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "RlM7U3dpdHplci1ib2xk", "--framer-font-family": "\"Switzer\", sans-serif", "--framer-font-size": "108.317px", "--framer-font-weight": "700", "--framer-letter-spacing": "0px", "--framer-line-height": "1em", "--framer-text-transform": "uppercase"}}>Bethany Robertson</motion.p></React.Fragment>} className={"framer-1qmszuq"} fonts={["FS;Switzer-bold"]} layoutDependency={layoutDependency} layoutId={"ZD9dPO1YN"} style={{"--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--framer-paragraph-spacing": "0px"}} verticalAlignment={"top"} viewBox={"0 0 1170 108"} withExternalLayout/></motion.a></Link></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-l7SQW [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-l7SQW .framer-1ep1dfx { display: block; }", ".framer-l7SQW .framer-1x59op2 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 0px 15px 0px 15px; position: relative; text-decoration: none; width: 1200px; }", ".framer-l7SQW .framer-1qmszuq { flex: 1 0 0px; height: auto; position: relative; white-space: pre; width: 1px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-l7SQW .framer-1x59op2 { gap: 0px; } .framer-l7SQW .framer-1x59op2 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-l7SQW .framer-1x59op2 > :first-child { margin-left: 0px; } .framer-l7SQW .framer-1x59op2 > :last-child { margin-right: 0px; } }", ".framer-l7SQW.framer-v-8l0vw8 .framer-1x59op2 { padding: 0px 10px 0px 10px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 108
 * @framerIntrinsicWidth 1200
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]},"y0IJ4UOM7":{"layout":["fixed","auto"]}}}
 * @framerVariables {"PREstkul_":"link"}
 */
const FramerAxUajy7yT: React.ComponentType<Props> = withCSS(Component, css, "framer-l7SQW") as typeof Component;
export default FramerAxUajy7yT;

FramerAxUajy7yT.displayName = "Logo";

FramerAxUajy7yT.defaultProps = {height: 108, width: 1200};

addPropertyControls(FramerAxUajy7yT, {variant: {options: ["Q7i21dvUF", "y0IJ4UOM7"], optionTitles: ["Logo Desktop", "Logo Mobile"], title: "Variant", type: ControlType.Enum}, PREstkul_: {title: "Link", type: ControlType.Link}} as any)

addFonts(FramerAxUajy7yT, [{family: "Switzer", moduleAsset: {localModuleIdentifier: "local-module:canvasComponent/AxUajy7yT:default", url: "https://framerusercontent.com/third-party-assets/fontshare/wf/HBNTRIISA5MEXGL5WPYI7CV2HIWTDV3Q/YDPDINVT673XLXNSTMLG4JNCZZMVVNPN/Y7SCNZJOT2MW5ADSGOFLDGH4TNL4JCQY.woff2"}, style: "normal", url: "https://framerusercontent.com/third-party-assets/fontshare/wf/HBNTRIISA5MEXGL5WPYI7CV2HIWTDV3Q/YDPDINVT673XLXNSTMLG4JNCZZMVVNPN/Y7SCNZJOT2MW5ADSGOFLDGH4TNL4JCQY.woff2", weight: "700"}])